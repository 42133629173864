#partners{
  .section-title {
    position: absolute;
    left: -65px;
    bottom: 100px;
    transform: rotate(-90deg);
    font-weight: 300;
    font-size: 3rem;
    text-transform: lowercase;
  }

  .birds-animated {
    max-width: 350px;
    width: 25vw;
    position: absolute;
    right: 5vw;
    top: 5rem;
  }

  .tree-animated {
    width: 700px;
    position: absolute;
    right: -18rem;
    bottom: 0rem;
  }

  .small-logo-animated {
    position: absolute;
    right: 10rem;
    bottom: 0rem;
  }

  .content-container {
    display: flex;
    width: 70%;
    margin: 5%;
    text-align: left;
    .col {
      width: 50%;
      h3{
        margin-bottom: 3rem;
      }
      .columns-wrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .column-holder {
          display: flex;
          flex-direction: column;
          margin-right: 3rem;
          a {
            text-decoration: none;
            color: black;
            margin-bottom: 1rem;
          }
          strong {
            margin-bottom: 0.5rem;
          }
          p {
            margin: 0;
            margin-bottom: 0.5rem;
          }
        }
        .column-holderTEMP {
          display: flex;
          flex-direction: column;
          margin-right: 3rem;
          margin-bottom: 1rem;
          a {
            text-decoration: none;
            color: black;
            margin-bottom: 1rem;
          }
          p {
            margin: 0;
            margin-bottom: 0.5rem;
            strong {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 1024px){
  #partners{
    .content-container{
      flex-direction: column;
      width: 90%;
      transform: translateY(-30%);
      .col{
        width: 100%;
        h3{
          margin-bottom: 1rem;
          margin-top: 2rem;
        }
        .columns-wrapper{
          .column-holder{
            min-width: 35%;

          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  #partners{
    .section-title{
      bottom: 5rem;
    }
    img{
      z-index: -1;
    }
    .content-container{
      transform: unset;
      background: rgba(255, 255, 255, 0.8);
      margin-left: 3rem;
      padding: 1rem;
      width: 80%;
      justify-content: flex-start;
      .col{
        h3{
          margin-bottom: 0;
          margin-top: 0rem;
        }
        .columns-wrapper{
          flex-direction: column;
          grid-template-columns: repeat(1, 1fr);
            .column-holderTEMP {
              margin-bottom: 0.5rem;
              margin-right: 1.5rem;
              p {
                margin: 0 0 0.5rem;
                strong {
                  margin-bottom: 0.5rem;
                }
              }
            }
            a {
              margin: 0;
            }
            strong {
              margin: 5px 0 0;
            }
            p {
              margin: 0;
            }
          }
      }
    }
  }
}