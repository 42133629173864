.slider-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
  width: 65%;
  margin-left: auto;
  left: -10rem;
}
.slider-wrapper .swiper {
  width: 85%;
  margin: 0;
  max-width: 900px;
}
.slider-wrapper .swiper-navigation {
  width: 5%;
  margin-left: 2rem;
}
.slider-wrapper .swiper-navigation .left-arrow-navigation {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  background-image: url("../../../public/assets/exports/arrows/arrow-left.png") !important;
  background-repeat: no-repeat;
  background-position: left;
  width: 90px;
  height: 60px;
  transition: 0.3s;
}
.slider-wrapper .swiper-navigation .left-arrow-navigation:hover {
  width: 100px;
  margin-left: -10px;
}
.slider-wrapper .swiper-navigation .right-arrow-navigation {
  cursor: pointer;
  position: absolute;
  bottom: 60px;
  background-image: url("../../../public/assets/exports/arrows/arrow-right.png") !important;
  background-repeat: no-repeat;
  background-position: right;
  width: 90px;
  height: 60px;
  transition: 0.3s;
}
.slider-wrapper .swiper-navigation .right-arrow-navigation:hover {
  width: 100px;
}
.slider-wrapper .swiper-slide {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 1024px) {
  .slider-section {
    max-width: 100vw;
  }

  .slider-wrapper {
    width: 70%;
  }
  .slider-wrapper .swiper-slide {
    grid-template-columns: repeat(2, 1fr);
  }
  .slider-wrapper .swiper-navigation {
    width: 10%;
  }
  .slider-wrapper .swiper-navigation .left-arrow-navigation, .slider-wrapper .swiper-navigation .right-arrow-navigation {
    width: 50px;
  }
  .slider-wrapper .swiper-navigation .left-arrow-navigation:hover, .slider-wrapper .swiper-navigation .right-arrow-navigation:hover {
    width: 55px;
  }
}
@media (max-width: 767px) {
  .slider-wrapper {
    width: 90%;
    margin: auto;
    left: 0;
    flex-direction: column;
  }
  .slider-wrapper .swiper-slide {
    grid-template-columns: repeat(1, 1fr);
  }
  .slider-wrapper .swiper-navigation {
    min-height: 80px;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 50%;
    margin-left: auto;
    margin-right: 2rem;
  }
  .slider-wrapper .swiper-navigation .left-arrow-navigation {
    left: 0;
    top: 0;
  }
  .slider-wrapper .swiper-navigation .right-arrow-navigation {
    right: 0;
    top: 0;
  }
}

