#home .home-section-birds-animated {
  width: 20vw;
  max-width: 300px;
  position: absolute;
  top: 80px;
  right: 50px;
}
#home .logo {
  width: 350px;
  height: 184px;
}
#home .bird-graphic-animated {
  width: 170px;
  position: absolute;
  right: 0;
  bottom: 45px;
  z-index: 0;
}
#home .tree {
  position: absolute;
  left: 0;
  width: auto;
  height: 100%;
  transform: translateX(-40vh);
}
#home .content-wrapper {
  height: 100%;
  width: 46%;
  margin-left: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#home .content-wrapper h3 {
  width: 80%;
  font-weight: 300;
  font-size: 1.4rem;
  text-align: center;
  padding-top: 30px;
}
#home .content-wrapper p {
  font-weight: 400;
  font-size: 1rem;
  text-align: left;
  width: 115%;
  margin-left: auto;
}

@media (max-width: 1024px) {
  #home::before {
    display: none;
  }
  #home .tree {
    z-index: -1;
    height: 125vh;
    left: -50vh;
    bottom: -2%;
  }
  #home .content-wrapper {
    width: 90%;
    margin: auto;
    padding: 1rem;
  }
  #home .content-wrapper p {
    font-size: 1rem;
    text-align: center;
    width: 100%;
  }
  #home .logo {
    width: 60%;
    height: auto;
    max-width: 300px;
    padding-top: 2rem;
  }
  #home .bird-graphic-animated {
    display: none;
  }
  #home .home-section-birds-animated {
    z-index: -1;
  }

  .home-section-birds-animated {
    width: 300px;
    position: absolute;
    top: 80px;
    right: 50px;
  }
}


