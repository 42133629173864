#team .section-title {
  position: absolute;
  left: -25px;
  bottom: 56px;
  transform: rotate(-90deg);
  font-weight: 300;
  font-size: 3rem;
  text-transform: lowercase;
}
#team .tree-animated {
  width: 400px;
  position: absolute;
  right: -2rem;
  bottom: 0rem;
}
#team .small-logo-animated {
  position: absolute;
  right: 7rem;
  bottom: 0rem;
}
#team .content-container {
  width: 75%;
  margin-left: 10%;
  text-align: left;
}
#team .members-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  width: 100%;
  height: 60vh;
  margin-top: 10vh;
}
#team .team-members {
  display: flex;
  margin-left: 6%;
  justify-content: space-between;
  width: 90%;
}
#team .team-members .team-column h3 {
  margin-bottom: 0rem;
  font-size: 0.9rem;
}
#team .team-members .team-column ul {
  margin-top: 0.5rem;
  list-style: none;
  padding-left: 0;
}
#team .team-members .team-column ul li {
  list-style: none;
  font-size: 12px;
}

.single-member {
  position: relative;
}
.single-member .member-data {
  background-color: rgba(255, 255, 255, 0.75);
  border: 1px solid #cdcdcd;
  position: absolute;
  left: 77px;
  top: 30px;
  right: 50px;
  padding: 0 25px 0 35px;
  z-index: 1;
}
.single-member h3.member-name {
  margin-bottom: 0;
  padding-bottom: 0;
}
.single-member h4.member-role {
  margin-top: 0;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0;
}
.single-member .member-bio p {
  font-size: 12px;
}
.single-member img {
  width: 100px;
  height: 115px;
  object-fit: cover;
  object-position: center;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  padding: 2px;
  background-color: white;
  border: 1px solid #cdcdcd;
}

@media (max-width: 1024px) {
  #team img {
    z-index: -1;
  }
  #team .content-container {
    width: 95%;
    margin: auto;
  }
  #team .content-container .members-grid-wrapper {
    grid-template-columns: 1fr;
    grid-row-gap: 6rem;
    margin-top: 2rem;
  }
  #team .content-container .members-grid-wrapper .single-member img {
    top: 50px;
    z-index: 19 !important;
  }
  #team .content-container .members-grid-wrapper .single-member h3 {
    margin-top: 0.5rem;
  }
  #team .content-container .team-members {
    width: 95%;
    margin-top: 6rem;
    background: rgba(255, 255, 255, 0.8);
  }
  #team .content-container .team-members .team-column {
    padding-left: 0.5rem;
  }
}
@media (max-width: 767px) {
  #team .content-container {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #team .content-container .team-members {
    flex-direction: column;
    margin-top: 1.5rem;
    margin-bottom: 4rem;
  }
  #team .content-container .team-members .team-column {
    padding-left: 1.5rem;
  }
  #team .content-container .team-members .team-column ul {
    margin: 0;
  }
  #team .content-container .team-members .team-column ul li {
    display: inline;
    margin-right: 1rem;
  }
  #team .content-container .members-grid-wrapper {
    grid-row-gap: 5px;
    height: unset;
  }
  #team .content-container .members-grid-wrapper .single-member {
    text-align: right;
  }
  #team .content-container .members-grid-wrapper .single-member img {
    top: unset;
  }
  #team .content-container .members-grid-wrapper .single-member .member-data {
    width: 82%;
    position: relative;
    left: 13%;
    margin: 0;
    padding: 0 1rem 0 0;
    margin-bottom: 1rem;
  }
  #team .content-container .members-grid-wrapper .single-member .member-bio p {
    font-size: 14px;
    margin-top: 2rem;
  }
}

