#filmography {
  .tree-animated {
    width: 450px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .section-title {
    position: absolute;
    left: -80px;
    bottom: 150px;
    transform: rotate(-90deg);
    font-weight: 300;
    font-size: 3rem;
    text-transform: lowercase;
  }

  .small-logo-animated {
    position: absolute;
    left: 160px;
    bottom: 0;
  }
  .slider-wrapper{
    width: 75%;
    .swiper {
      max-width: 1200px;
    }
  }
}

.film-card {
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: relative;
  border: 1px solid whitesmoke;
  margin: 8px;
  > img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 15px;
    transition: all 0.2s ease-in-out;
    max-height: 160px;
  }
  &:hover {
    cursor: pointer;
    > img {
      transform: scale(1.05);
    }
  }
  a.card-link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .film-body {
    display: flex;
    align-items: flex-start;
    .col-left {
      width: 70%;
      text-align: left;
      h4.film-title {
        font-size: 1.1rem;
        padding: 0;
        margin: 0;
      }
      ul.film-meta {
        li {
          font-size: 13px;
        }
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .col-right {
      ul.status-data {
        li {
          font-size: 12px;
        }
      }
    }
  }
  .arrow-link-element {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    .arrow-body {
      width: 40%;
      height: 1px;
      background-color: gray;
    }
    .arrow-point {
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      border-top: 1px solid gray;
      border-right: 1px solid gray;
    }
  }
}
@media(max-width: 767px){
  #filmography{
    .slider-wrapper{
      width: 90%;
      margin-left: 10%;
    }
    .film-card{
      padding-bottom: 0;
      h4 {
        font-size: 1rem;
      }
    }
    .slider-wrapper .swiper {
      width: 90%;
    }
    .section-title {
      left: -100px;
    }
  }
}