.image-gallery-slide-wrapper {
  display: none;
}

.image-gallery-thumbnails-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.image-gallery-thumbnails-container button img {
  object-fit: cover;
  height: 90px;
}

.fullscreen .image-gallery-slide-wrapper {
  display: block;
}
.fullscreen .image-gallery-thumbnails-container {
  display: block;
}
.fullscreen .image-gallery-thumbnails-container button {
  width: 100px;
}
.fullscreen .image-gallery-thumbnails-container button img {
  height: 100px;
}


