#education2 {
  overflow-x: hidden;
}
#education2 .section-title {
  position: absolute;
  left: -65px;
  bottom: 106px;
  transform: rotate(-90deg);
  font-weight: 300;
  font-size: 3rem;
  text-transform: lowercase;
}
#education2 img {
  width: 100%;
}
#education2 h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
#education2 .single-post-content {
  width: 100vw;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#education2 .content-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}
#education2 .top-row {
  display: flex;
}
#education2 .top-row .horizontal-line-x {
  flex: 1;
  display: flex;
  align-items: center;
}
#education2 .top-row .line {
  height: 1px;
  width: 90%;
  background-color: black;
  margin: 0 20px;
}
#education2 .bottom-row {
  display: flex;
  width: 100%;
}
#education2 .bottom-row .col {
  width: 33.3333333333vw;
}
#education2 .bottom-row .middle-col {
  margin: 0 50px;
  flex-shrink: 0;
}
#education2 .bottom-row .post-text {
  font-size: 18px;
  text-align: left;
}
#education2 .close-btn-animated {
  position: relative;
  width: 50px;
  right: -32px;
}
#education2 button.x-btn.x-btn-animated {
  width: 50px;
  background: none;
  border: 0;
  box-sizing: border-box;
  color: transparent;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  left: 50%;
  letter-spacing: 1.5px;
  line-height: 50px;
  outline: none;
  overflow: hidden;
  padding: 10px 0 0;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in;
  width: 100px;
}
#education2 button.x-btn.x-btn-animated::before,
#education2 button.x-btn.x-btn-animated::after {
  background-color: black;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  transform-origin: center left;
  transition: all 0.2s ease-in;
  width: 141.4214px;
  z-index: -1;
}
#education2 button.x-btn.x-btn-animated::before {
  top: 0;
  transform: rotate(45deg);
}
#education2 button.x-btn.x-btn-animated::after {
  bottom: 0;
  transform: rotate(-45deg);
}
#education2 button.x-btn.x-btn-animated:hover {
  color: #fff;
}
#education2 button.x-btn.x-btn-animated:hover::before,
#education2 button.x-btn.x-btn-animated:hover::after {
  height: 50px;
  transform: rotate(0deg);
}

@media (max-width: 767px) {
  #education2 {
    position: fixed;
  }
  #education2 .content-container {
    padding: 0;
  }
  #education2 .content-container .top-row h1 {
    text-align: left;
  }
  #education2 .content-container .bottom-row {
    margin-bottom: 10vh;
  }
  #education2 .content-container .bottom-row .col img {
    width: 100% !important;
    height: unset !important;
    margin: 2rem 0;
  }
  #education2 .content-container .bottom-row .middle-col {
    width: 86% !important;
  }
}

