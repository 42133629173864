#news {
  .section-title {
    position: absolute;
    left: -9vw;
    bottom: 150px;
    transform: rotate(-90deg);
    font-weight: 300;
    font-size: 3rem;
    text-transform: lowercase;
  }
  
  .birds-animated {
    width: 130px;
    position: absolute;
    top: 8rem;
    left: 3rem;
  }

  .tree-animated {
    width: 600px;
    position: absolute;
    bottom: -50px;
    left: -250px;
  }

  .post-card {
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    max-width: 330px;
    padding: 8px;
    position: relative;
    &:hover {
      cursor: pointer;
      > img {
        transform: scale(1.05);
      }
    }
    > img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      margin-bottom: 15px;
      transition: all 0.2s ease-in-out;
    }

    .blank-image{
        width: 100%;
        height: 180px;
        object-fit: cover;
        margin-bottom: 15px;
        transition: all 0.2s ease-in-out;
    }

    .post-date {
      text-align: left;
      padding: 0;
      margin: 0;
      color: gray;
      margin-bottom: 5px;
      font-size: 11px;
      text-decoration: none;
    }
    .post-title {
      text-align: left;
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      margin-bottom: 20px;
      text-decoration: none;
    }
    .arrow-link-element {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .arrow-body {
        width: 100%;
        height: 1px;
        background-color: gray;
      }
      .arrow-point {
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        border-top: 1px solid gray;
        border-right: 1px solid gray;
      }
    }
    a.link {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
