#singlepost img {
  width: 100%;
}
#singlepost .close-btn-animated {
  position: relative;
  width: 50px;
  right: -32px;
}
#singlepost button.x-btn.x-btn-animated {
  width: 50px;
  background: none;
  border: 0;
  box-sizing: border-box;
  color: transparent;
  cursor: pointer;
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  left: 50%;
  letter-spacing: 1.5px;
  line-height: 50px;
  outline: none;
  overflow: hidden;
  padding: 10px 0 0;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in;
  width: 100px;
}
#singlepost button.x-btn.x-btn-animated::before,
#singlepost button.x-btn.x-btn-animated::after {
  background-color: black;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  transform-origin: center left;
  transition: all 0.2s ease-in;
  width: 141.4214px;
  z-index: -1;
}
#singlepost button.x-btn.x-btn-animated::before {
  top: 0;
  transform: rotate(45deg);
}
#singlepost button.x-btn.x-btn-animated::after {
  bottom: 0;
  transform: rotate(-45deg);
}
#singlepost button.x-btn.x-btn-animated:hover {
  color: #fff;
}
#singlepost button.x-btn.x-btn-animated:hover::before,
#singlepost button.x-btn.x-btn-animated:hover::after {
  height: 50px;
  transform: rotate(0deg);
}
#singlepost .bottom-row {
  display: flex;
  width: 100%;
}
#singlepost .single-post-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
#singlepost .post-featured-image {
  width: 20vw;
  height: 30vw;
}
#singlepost .content-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}
#singlepost .top-row {
  display: flex;
}
#singlepost .top-row .horizontal-line-x {
  flex: 1;
  display: flex;
  align-items: center;
}
#singlepost .top-row .line {
  height: 1px;
  width: 90%;
  background-color: black;
  margin: 0 20px;
}
#singlepost .top-row .close-btn-animated {
  position: relative;
  width: 50px;
  right: -32px;
}
#singlepost .bottom-row {
  display: flex;
  width: 100%;
}
#singlepost .bottom-row .col {
  width: 35vw;
}
#singlepost .bottom-row .col .trailer-video {
  width: 100% !important;
  padding-top: 56.25%;
  height: unset !important;
  position: relative;
}
#singlepost .bottom-row .col .trailer-video div {
  position: absolute;
  top: 0;
}
#singlepost .bottom-row .middle-col {
  margin: 0 50px;
  flex-shrink: 0;
  text-align: left;
}
#singlepost .bottom-row .post-text {
  font-size: 18px;
}
#singlepost .line-title-block {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
#singlepost .line-title-block .block-label strong {
  font-size: 20px;
}
#singlepost .line-title-block .block-line-horizontal {
  height: 1px;
  background: black;
  flex: 1;
  margin-left: 20px;
}
#singlepost ul.crew-list {
  list-style: none;
  padding: 0;
}
#singlepost ul.crew-list li {
  font-size: 17px;
  margin-bottom: 3px;
}
#singlepost .mt20 {
  margin-top: 20px;
}
#singlepost .support-logos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
#singlepost .support-logos a {
  padding: 0.5rem;
}

@media (max-width: 1024px) {
  .bottom-row {
    flex-wrap: wrap;
  }
  .bottom-row .middle-col {
    margin-left: auto;
    width: 100%;
    text-align: left !important;
  }
  .bottom-row .new-row {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    justify-content: space-between;
  }
  .bottom-row .new-row .widget {
    display: flex;
    width: 45%;
    flex-direction: column;
  }
  .bottom-row .new-row .widget .line-title-block {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .post-featured-image {
    width: 60vw !important;
    height: auto !important;
    margin: 1rem auto auto;
  }

  .bottom-row .new-row {
    flex-direction: column;
    align-items: center;
  }
  .bottom-row .new-row .widget {
    width: 80%;
  }

  #singlepost {
    position: fixed;
  }
  #singlepost .single-post-content .content-container {
    padding-top: 1rem;
    overflow-x: hidden;
  }
  #singlepost .single-post-content .content-container .bottom-row {
    margin-bottom: 4rem;
  }
  #singlepost .single-post-content .content-container .bottom-row .col {
    width: 100% !important;
    margin: 0;
    padding: 5% 0;
  }
}

