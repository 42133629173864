#education {
  .section-title {
    position: absolute;
    left: -65px;
    bottom: 106px;
    transform: rotate(-90deg);
    font-weight: 300;
    font-size: 3rem;
    text-transform: lowercase;
  }

  .small-trees-animated {
    width: 250px;
    position: absolute;
    left: 6rem;
    bottom: 0;
  }

  .tree-animated {
    width: 220px;
    position: absolute;
    right: 1rem;
    bottom: 0rem;
  }

  .small-logo-animated {
    position: absolute;
    right: 8rem;
    bottom: 0rem;
  }

  .content-container {
    width: 75%;
    margin-left: 10%;
    margin-top: 100px;
  }
  .members-grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    width: 100%;
    height: 60vh;
  }

  .home-section-birds-animated {
    width: 300px;
    position: absolute;
    top: 80px;
    right: 50px;
  }
}

.img-style {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.topic {
  margin-bottom: 0;
  text-align: left;
}

.flip-box {
  background-color: transparent;
  width: 73%;
  height: 20vh;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d; 
  &:hover{
    .flip-box-front{
      display: none;
    }
    .flip-box-back{
      display: block;
    }
  }
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  transition: 0.3s;
}

.flip-box-back {
  background-color: rgba(0, 0, 0, 1);
  color: white;
  display: none;
  z-index: 5;
  img{
    opacity: 0.3;
  }
}

.pStyle{
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  margin: 0;
  font-size: 15px;
}

@media (max-width: 1024px) {
  #education{
    .home-section-birds-animated{
      display: none;
    }
    .content-container{
      width: 90%;
      margin:auto;
      position: absolute;
      top: 5%;
      left: 5%;
      .topic{
        font-size: 1.2rem;
        margin-bottom: 1rem;
      };
      img {
        height: 15vh;
      }
    }
  }
}
@media (max-width: 767px) {
  #education {
    img{
      z-index: -1;
    }
    .section-title{
      left: -5rem;
    }
    .content-container{
      width: 80%;
      left: 15%;
      max-height: 83%;
      z-index: 3;
      overflow-y: scroll;
      background: rgba(255,255,255,0.8);
      .members-grid-wrapper{
        grid-template-columns: 1fr;
        .flip-box{
          width: 100%;
          height: 15vh;
        }
      }
    }
  }
}
