@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  max-width: 100%;
  overflow-x: hidden;
  /*overflow-y: hidden;*/
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  overflow-wrap: break-word;
}

.section {
  position: absolute;
  max-width: 100%;
  height: 100vh !important;
  overflow: hidden;
  background: white;
}

.fixed-section{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color:white;
  z-index: 2000;
}

.section::after {
  content: "";
  height: 15px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

#home::before {
  content: "";
  background-color: #000;
  position: absolute;
  height: 50px;
  top: 0;
  left: 0;
  width: 100%;
}

.sticky {
  position: fixed;
  top: 0;
  height: 30px;
  width: 100%;
}

.swiper-button-disabled{
  display: none;
}

@media (max-width: 767px) {
  html, body{
    overflow: scroll;
  }
  .section{
    position: relative;
    background: none;
  }
}
