.image-gallery-slide-wrapper {
  display: none;
}
.image-gallery-thumbnails-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  button {
    img {
      object-fit: cover;
      height: 90px;
    }
  }
}
.fullscreen {
  .image-gallery-slide-wrapper {
    display: block;
  }
  .image-gallery-thumbnails-container {
    display: block;
    button {
      width: 100px;
      img {
        height: 100px;
      }
    }
  }
}
