.main-menu {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #000;
  position: absolute;
  bottom: 0;
  justify-content: center;
  padding: 15px 0;
  z-index: 50;
  left: 0;
}
.main-menu ul {
  padding: 0;
  margin: 0;
  width: 50%;
  list-style: none;
  display: flex;
  justify-content: center;
}
.main-menu ul li {
  color: white;
  border-right: 1px solid #fff;
}
.main-menu ul li:last-child {
  color: white;
  border-right: none;
}
.main-menu ul li a {
  display: block;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  padding: 5px 25px;
  transition: all 0.2s ease;
}
.main-menu ul li a:hover {
  text-decoration: underline;
}

.bm-burger-button {
  position: fixed;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  padding: 0 5px 0.8rem 1rem;
  background: black;
  border-radius: 0 0 0 3rem;
}

.bm-cross-button {
  width: 40px !important;
  height: 40px !important;
}

.bm-item-list {
  background: black;
}
.bm-item-list .bm-item {
  list-style-type: none;
  margin-top: 4rem;
  margin-right: 40px;
}
.bm-item-list .bm-item :first-child {
  border-top: 2px solid white;
}
.bm-item-list .bm-item .nav-item {
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  border-bottom: 2px solid white;
}
.bm-item-list .bm-item .nav-item a {
  border: none;
  color: white;
  text-decoration: none;
}


