#contact .tree-animated {
  position: absolute;
  bottom: -56px;
  left: -400px;
  height: 100%;
}
#contact .small-logo-animated {
  position: absolute;
  left: 19rem;
  bottom: 0rem;
}
#contact .tree-birdhouse-animated {
  width: 230px;
  position: absolute;
  right: 1rem;
  bottom: 0;
}
#contact .content-container {
  width: 70%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: 375px;
}
#contact .content-container p {
  text-align: left;
}
#contact .content-container::-webkit-scrollbar {
  display: none;
}
#contact .content-container .top-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
#contact .content-container .top-row .col-left {
  flex: 1;
  padding: 0 30px;
  text-align: left;
}
#contact .content-container .top-row .col-right {
  flex: 1;
  padding: 0 30px;
  text-align: left;
}
#contact .content-container .col-right {
  border-left: 1px solid black;
  padding-left: 35px;
}
#contact .content-container .bottom-row {
  margin: 0 30px 0 30px;
  padding-top: 15px;
  border-top: 1px solid black;
}

@media (max-width: 1024px) {
  #contact img {
    z-index: -1;
  }
  #contact .content-container {
    height: unset;
    background: rgba(255, 255, 255, 0.8);
    width: 90%;
    margin-left: 5%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  #contact img {
    z-index: -1;
  }
  #contact .content-container {
    position: relative;
    width: 95%;
    height: 90vh;
    margin-top: 5vh;
    margin-left: 2.5%;
    top: unset;
    transform: unset;
    display: block;
  }
  #contact .content-container .top-row {
    font-size: 14px;
    flex-direction: column;
    padding-bottom: 10px;
  }
  #contact .content-container .top-row .col-left, #contact .content-container .top-row .col-right {
    padding: 0 10px;
  }
  #contact .content-container .top-row .col-left p, #contact .content-container .top-row .col-right p {
    margin-bottom: 0;
  }
  #contact .content-container .bottom-row {
    font-size: 14px;
    margin: 0;
    padding: 0 10px;
  }
}
.btn-contact {
  border: 0;
  background: black;
  color: white;
  padding: 1rem;
  border-radius: 2px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.3s;
}
.btn-contact:hover {
  padding: 1rem 1.5rem;
}

