#partners .section-title {
  position: absolute;
  left: -65px;
  bottom: 100px;
  transform: rotate(-90deg);
  font-weight: 300;
  font-size: 3rem;
  text-transform: lowercase;
}
#partners .birds-animated {
  max-width: 350px;
  width: 25vw;
  position: absolute;
  right: 5vw;
  top: 5rem;
}
#partners .tree-animated {
  width: 700px;
  position: absolute;
  right: -18rem;
  bottom: 0rem;
}
#partners .small-logo-animated {
  position: absolute;
  right: 10rem;
  bottom: 0rem;
}
#partners .content-container {
  display: flex;
  width: 70%;
  margin: 5%;
  text-align: left;
}
#partners .content-container .col {
  width: 50%;
}
#partners .content-container .col h3 {
  margin-bottom: 3rem;
}
#partners .content-container .col .columns-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
#partners .content-container .col .columns-wrapper .column-holder {
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
}
#partners .content-container .col .columns-wrapper .column-holder a {
  text-decoration: none;
  color: black;
  margin-bottom: 1rem;
}
#partners .content-container .col .columns-wrapper .column-holder strong {
  margin-bottom: 0.5rem;
}
#partners .content-container .col .columns-wrapper .column-holder p {
  margin: 0;
  margin-bottom: 0.5rem;
}
#partners .content-container .col .columns-wrapper .column-holderTEMP {
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  margin-bottom: 1rem;
}
#partners .content-container .col .columns-wrapper .column-holderTEMP a {
  text-decoration: none;
  color: black;
  margin-bottom: 1rem;
}
#partners .content-container .col .columns-wrapper .column-holderTEMP p {
  margin: 0;
  margin-bottom: 0.5rem;
}
#partners .content-container .col .columns-wrapper .column-holderTEMP p strong {
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  #partners .content-container {
    flex-direction: column;
    width: 90%;
    transform: translateY(-30%);
  }
  #partners .content-container .col {
    width: 100%;
  }
  #partners .content-container .col h3 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  #partners .content-container .col .columns-wrapper .column-holder {
    min-width: 35%;
  }
}
@media (max-width: 767px) {
  #partners .section-title {
    bottom: 5rem;
  }
  #partners img {
    z-index: -1;
  }
  #partners .content-container {
    transform: unset;
    background: rgba(255, 255, 255, 0.8);
    margin-left: 3rem;
    padding: 1rem;
    width: 80%;
    justify-content: flex-start;
  }
  #partners .content-container .col h3 {
    margin-bottom: 0;
    margin-top: 0rem;
  }
  #partners .content-container .col .columns-wrapper {
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
  }
  #partners .content-container .col .columns-wrapper .column-holderTEMP {
    margin-bottom: 0.5rem;
    margin-right: 1.5rem;
  }
  #partners .content-container .col .columns-wrapper .column-holderTEMP p {
    margin: 0 0 0.5rem;
  }
  #partners .content-container .col .columns-wrapper .column-holderTEMP p strong {
    margin-bottom: 0.5rem;
  }
  #partners .content-container .col .columns-wrapper a {
    margin: 0;
  }
  #partners .content-container .col .columns-wrapper strong {
    margin: 5px 0 0;
  }
  #partners .content-container .col .columns-wrapper p {
    margin: 0;
  }
}

