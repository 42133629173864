#contact {
  .tree-animated {
    position: absolute;
    bottom: -56px;
    left: -400px;
    height: 100%;
  }

  .small-logo-animated {
    position: absolute;
    left: 19rem;
    bottom: 0rem;
  }

  .tree-birdhouse-animated {
    width: 230px;
    position: absolute;
    right: 1rem;
    bottom: 0;
  }

  .content-container {
    p {
      text-align: left;
    }

    width: 70%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-left: 375px;

    &::-webkit-scrollbar {
      display: none;
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;

      .col-left {
        flex: 1;
        padding: 0 30px;
        text-align: left;
      }

      .col-right {
        flex: 1;
        padding: 0 30px;
        text-align: left;
      }
    }

    .col-right {
      border-left: 1px solid black;
      padding-left: 35px;
    }

    .bottom-row {
      margin: 0 30px 0 30px;
      padding-top: 15px;
      border-top: 1px solid black;
    }
  }

}

@media (max-width: 1024px) {
  #contact {
    img {
      z-index: -1;
    }

    .content-container {
      height: unset;
      background: rgba(255, 255, 255, 0.8);
      width: 90%;
      margin-left: 5%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media (max-width: 767px) {
  #contact {
    img {
      z-index: -1;
    }

    .content-container {
      position: relative;
      width: 95%;
      height: 90vh;
      margin-top: 5vh;
      margin-left: 2.5%;
      top: unset;
      transform: unset;
      display: block;
      .top-row {
        font-size: 14px;
        flex-direction: column;

        .col-left, .col-right {
          padding: 0 10px;

          p {
            margin-bottom: 0;
          }
        }

        padding-bottom: 10px;
      }

      .bottom-row {
        font-size: 14px;
        margin: 0;
        padding: 0 10px;
      }
    }
  }
}

.btn-contact {
  border: 0;
  background: black;
  color: white;
  padding: 1rem;
  border-radius: 2px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.3s;
  &:hover{
    padding: 1rem 1.5rem;
  }
}