#filmography .tree-animated {
  width: 450px;
  position: absolute;
  left: 0;
  bottom: 0;
}
#filmography .section-title {
  position: absolute;
  left: -80px;
  bottom: 150px;
  transform: rotate(-90deg);
  font-weight: 300;
  font-size: 3rem;
  text-transform: lowercase;
}
#filmography .small-logo-animated {
  position: absolute;
  left: 160px;
  bottom: 0;
}
#filmography .slider-wrapper {
  width: 75%;
}
#filmography .slider-wrapper .swiper {
  max-width: 1200px;
}

.film-card {
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  padding: 8px;
  position: relative;
  border: 1px solid whitesmoke;
  margin: 8px;
}
.film-card > img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 15px;
  transition: all 0.2s ease-in-out;
  max-height: 160px;
}
.film-card:hover {
  cursor: pointer;
}
.film-card:hover > img {
  transform: scale(1.05);
}
.film-card a.card-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.film-card .film-body {
  display: flex;
  align-items: flex-start;
}
.film-card .film-body .col-left {
  width: 70%;
  text-align: left;
}
.film-card .film-body .col-left h4.film-title {
  font-size: 1.1rem;
  padding: 0;
  margin: 0;
}
.film-card .film-body .col-left ul.film-meta li {
  font-size: 13px;
}
.film-card .film-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.film-card .film-body .col-right ul.status-data li {
  font-size: 12px;
}
.film-card .arrow-link-element {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.film-card .arrow-link-element .arrow-body {
  width: 40%;
  height: 1px;
  background-color: gray;
}
.film-card .arrow-link-element .arrow-point {
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  border-top: 1px solid gray;
  border-right: 1px solid gray;
}

@media (max-width: 767px) {
  #filmography .slider-wrapper {
    width: 90%;
    margin-left: 10%;
  }
  #filmography .film-card {
    padding-bottom: 0;
  }
  #filmography .film-card h4 {
    font-size: 1rem;
  }
  #filmography .slider-wrapper .swiper {
    width: 90%;
  }
  #filmography .section-title {
    left: -100px;
  }
}

