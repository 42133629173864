#news2 {
  .section-title {
    position: absolute;
    left: -10vw;
    bottom: 106px;
    transform: rotate(-90deg);
    font-weight: 300;
    font-size: 3rem;
    text-transform: lowercase;
  }
  
  img {
    width: 100%;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  .post-featured-image{
    width: 20vw;
    height: calc(20vw*1.5);
  }

  .single-post-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .content-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
  }

  .top-row {
    display: flex;

    .horizontal-line-x {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .line {
      height: 1px;
      width: 90%;
      background-color: black;
      margin: 0 20px;
    }
  }

  .bottom-row {
    display: flex;
    width: 100%;

    .col {
      width: calc(73vw / 3);
    }

    .middle-col {
      margin: 0 50px;
      flex-shrink: 0;
    }

    .post-text {
      font-size: 18px;
    }
  }

  .close-btn-animated {
    position: relative;
    width: 50px;
    right: -32px;
  }
  button.x-btn.x-btn-animated {
    width: 50px;
    background: none;
    border: 0;
    box-sizing: border-box;
    color: transparent;
    cursor: pointer;
    font-family: "Josefin Sans", sans-serif;
    font-size: 18px;
    left: 50%;
    letter-spacing: 1.5px;
    line-height: 50px;
    outline: none;
    overflow: hidden;
    padding: 10px 0 0;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in;
    width: 100px;
  }

  button.x-btn.x-btn-animated::before,
  button.x-btn.x-btn-animated::after {
    background-color: black;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    transform-origin: center left;
    transition: all 0.2s ease-in;
    width: 141.4214px;
    z-index: -1;
  }

  button.x-btn.x-btn-animated::before {
    top: 0;
    transform: rotate(45deg);
  }

  button.x-btn.x-btn-animated::after {
    bottom: 0;
    transform: rotate(-45deg);
  }

  button.x-btn.x-btn-animated:hover {
    color: #fff;
  }

  button.x-btn.x-btn-animated:hover::before,
  button.x-btn.x-btn-animated:hover::after {
    height: 50px;
    transform: rotate(0deg);
  }
}

@media (max-width: 1024px) {
  #news2 {
    h1 {
      text-align: left;
      width: 70%;
      font-size: 1.4rem;
    }
    .bottom-row{
      flex-direction: column;
      .col{
        width: 70%;
        margin: 1.5rem 0 0;
        text-align: center;
      }
    }
    .single-post-content{
      .content-container{
        margin-left: 10%;
      }
      .col .middle-col {
        width: 100% !important;
      }
    }
  }
}
@media (max-width: 767px) {
  #news2{
    button.x-btn.x-btn-animated{
      transform: translate(-60%, -50%);
    }
    position: fixed;
    .single-post-content{
      margin-top: 2rem;
    }
    .content-container{
      padding: 0 !important;
      width: 80%;
      overflow-x: hidden;
    }
  }
  h1{
    width: 80% !important;
    font-size: 1.2rem !important;
  }
  .top-row{
    .horizontal-line-x{
      .line{
        display: none;
      }
      .x-btn{
        width: 80px;
      }
    }
  }
  .bottom-row{
    .col{
      width: 100% !important;
    }
  }
}
